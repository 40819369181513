// Footer.js
import React from 'react';
import useAuth from '../hooks/useAuth';

function Footer() {
      // Use the useAuth hook to get user information
  const { FullName, status } = useAuth();

  return (
    <div className="footer">
        <p>&copy; 2024 The Cha Nguyễn Văn Khối</p>
        <p>Hello, {FullName}!</p>
        <p>Status: {status}</p>
    </div>
  );
}

export default Footer;
