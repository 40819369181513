import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode'

const useAuth = () => 
{
    const token = Cookies.get('token');
    let isManager = false;
    let isAdmin = false;
    let status = "Employee";

    console.log("token:", token); // Log the decoded token

    if(token)
    {
        const decoded = jwtDecode(token)
        const {Username, FullName, roles} = decoded

        console.log("Decoded Token:", decoded); // Log the decoded token
    
        isManager = roles.includes('Manager')
        isAdmin = roles.includes('Admin')
    
        if (isManager) status = "Manager"
        if (isAdmin) status = "Admin"
    
    
        return {Username, FullName, roles, isManager, isAdmin, status}

    }

    return {Username: '', FullName: '', roles: [], isManager, isAdmin, status}
}

export default useAuth