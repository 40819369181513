// Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie'; // Import Cookies
import { useNavigate } from 'react-router-dom';

function Header() {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear the cookie
    Cookies.remove('token');
    // Redirect to login page
    navigate('/'); // Replace '/login' with the actual login page route
  };

  return (
    <div className="header">
      <Link to="/HomePage"><button>Home</button></Link> {/* Link to home page */}
      <button onClick={handleLogout}>Logout</button> {/* Logout button */}
    </div>
  );
}

export default Header;
