import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer'; // Import the Footer component
import Header from './Header'; // Import the Header component

function CheckInOutScreen()
{
  const [response, setResponse] = useState('');
  const token = Cookies.get('token');
  const navigate = useNavigate();

  const fetchUserIP = async () => {
    try
    {
      const response = await axios.get('https://ipapi.co/json/');
      if (response.status === 200)
      {
        return response.data.ip;
      }
      else
      {
        throw new Error('Failed to fetch public IP address');
      }
    }
    catch (error)
    {
      console.error('Error fetching public IP:', error.message);
      return null;
    }
  };

  const handleCheckIn = async () => {

    const UserIP = await fetchUserIP();
    if (!UserIP)
    {
      console.error('Public IP address not available');
      setResponse('Your IP address not available');
      return;
    }

    console.log('Public IP address:', UserIP);

    console.log('token:', token);

    try {
      const response = await axios.post(
        'https://thechanguyenvankhoi.server.daolin.top/check-in',
        {UserIP}, // Include public IP in the request body
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200)
      {
        setResponse(response.data);
      }
      else
      {
        /* should not reach here */
      }
    } catch (error)
    {
        if (error.response.status === 401)
        {
            setResponse('You must log in first');
            // Navigate to login if Access Denied
            setTimeout(() => {
                navigate('/');
              }, 2000); // Delay of 1 seconds
        }
        else
        {
            setResponse(`Error: ${error.message}`);
        }
    }
  };

  const handleCheckOut = async () => {
    try {
      const response = await axios.post('https://thechanguyenvankhoi.server.daolin.top/check-out', {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });

      if (response.status === 200) {
        setResponse(response.data);
      } else
      {
        /* should not reach here */
      }
    } catch (error) {
    
        if (error.response.status === 401)
        {
            setResponse('Unauthorized: Access Denied');
            // Navigate to login if Access Denied
            setTimeout(() => {
                navigate('/');
              }, 2000); // Delay of 1 seconds
        }
        else
        {
            setResponse(`Error: ${error.message}`);
        }
    }
  };

  return (
    <div>
      <Header/>
      <h2>Check-In/Check-Out</h2>
      <button onClick={handleCheckIn}>Check In</button>
      <button onClick={handleCheckOut}>Check Out</button>
      <p style={{ color: response.toLowerCase().includes('successful') ? 'green' : 'red' }}>{response}</p>
      {/* Footer */}
      <Footer/>
    </div>
  );
}

export default CheckInOutScreen;
