import React from 'react';
import { Link } from 'react-router-dom';
// import useAuth from '../hooks/useAuth';
import Footer from './Footer'; // Import the Footer component
import Header from './Header'; // Import the Header component

function HomePage() {

  return (
    <div>
      <Header/>
      <h1>Welcome to the Home Page</h1>
      <Link to="/check-in-out"><button>Check In/Out</button></Link>
      <Link to="/change-password"><button>Change Password</button></Link>
      <Link to="/update-office-ip"><button>Update Office IP</button></Link>
      {/* Footer */}
      <Footer/>
    </div>
  );
}

export default HomePage;
