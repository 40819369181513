import axios from 'axios';

axios.interceptors.request.use(request => {
  console.log('Request:', request);
  return request;
}, error => {
  console.error('Request error:', error);
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
  console.log('Response:', response);
  return response;
}, error => {
  console.error('Response error:', error);
  return Promise.reject(error);
});
