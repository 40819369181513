// UpdateOfficeIPPage.js
import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer'; // Import the Footer component
import Header from './Header'; // Import the Header component

function UpdateOfficeIPPage() {
  const [userIP, setUserIP] = useState('');
  const [response, setResponse] = useState('');
  const token = Cookies.get('token');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fetchUserIP = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        if (response.status === 200)
        {
          return response.data.ip;
        }
        else
        {
          throw new Error('Failed to fetch public IP address');
        }
      }
      catch (error)
      {
        console.error('Error fetching public IP:', error.message);
        return null;
      }
    };

    try {
      const UserIP = await fetchUserIP();
      if (!UserIP)
      {
        console.error('Public IP address not available');
        setResponse('Your IP address not available');
        return;
      }

      setUserIP(UserIP);

      const response = await axios.post(
        'https://thechanguyenvankhoi.server.daolin.top/update-office-ip',
        { officeIP: UserIP },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200)
      {
        setResponse(response.data);
      }
      else
      {
        /* should not reach here */
      }
    } catch (error)
    {
        if (error.response.status === 401)
        {
            setResponse('You must log in first');
            // Navigate to login if Access Denied
            setTimeout(() => {
                navigate('/');
              }, 2000); // Delay of 1 seconds
        }
        else
        {
            setResponse(`Error: ${error.message}`);
        }
    }
  };

  return (
    <div>
      <Header/>
      <h1>Update Office IP</h1>
      <form onSubmit={handleSubmit}>
        <button type="submit">Update</button>
      </form>
      {userIP && <p>Your IP Address: {userIP}</p>}
      <p style={{ color: response.toLowerCase().includes('successful') ? 'green' : 'red' }}>{response}</p>
      {/* Footer */}
      <Footer/>
    </div>
  );
}

export default UpdateOfficeIPPage;
