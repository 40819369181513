import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './axiosConfig'; // Import Axios interceptors configuration
import { useNavigate } from 'react-router-dom';
import * as CryptoJS from 'crypto-js';
import Footer from './Footer'; // Import the Footer component
import Header from './Header'; // Import the Header component

function ChangePasswordScreen()
{
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [response, setResponse] = useState('');
  const navigate = useNavigate();
  const token = Cookies.get('token');

  const handleChangePassword = async (e) => {
    e.preventDefault();

    console.log('Submitting change password form...'); // Debug message

    try
    {
      // Hash the passwords using SHA256
      const hashedOldPassword = CryptoJS.SHA256(oldPassword).toString(CryptoJS.enc.Hex);
      const hashedNewPassword = CryptoJS.SHA256(newPassword).toString(CryptoJS.enc.Hex);
      const hashedConfirmPassword = CryptoJS.SHA256(confirmPassword).toString(CryptoJS.enc.Hex);

      // Check if new password meets complexity requirements
      const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
      if (!passwordRegex.test(newPassword)) {
        setResponse('New password must be at least 8 characters long and contain at least one uppercase letter, one number, and one special character.');
        return;
      }

      if (hashedNewPassword !== hashedConfirmPassword)
      {
        setResponse('New password and confirm password do not match');
        return;
      }

      const response = await axios.post
      ( 'https://thechanguyenvankhoi.server.daolin.top/change-password',
        {
          oldPassword: hashedOldPassword,
          newPassword: hashedNewPassword,
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Change Password API request successful'); // Debug message

      if (response.status === 200)
      {
        setResponse('Password changed successfully');
        Cookies.remove('token'); // Remove old token from cookies

        setTimeout(() => {
          navigate('/');
        }, 2500); // Delay of milisecond(s)

      }
      else
      {
        setResponse(`Error: ${response.status}`);
      }
    }
    catch (error)
    {
      console.error('Error:', error);
      if (error.response.status === 400)
      {
        // Wrong password
        setResponse(error.response.data);
      }
      else if (error.response.status === 401)
      {
          setResponse('You must log in first');
          // Navigate to login if Access Denied
          setTimeout(() => {
              navigate('/');
            }, 1500); // Delay of miliseconds
      }
      else
      {
        setResponse(`Error: ${error.message}`);
      }
    }
  };

  return (
    <div>
      <Header/>
      <h2>Change Password</h2>
      <form onSubmit={handleChangePassword}>
        <div>
          <label>Old Password:</label>
          <input type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
        </div>
        <div>
          <label>New Password:</label>
          <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
        </div>
        <div>
          <label>Confirm Password:</label>
          <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        </div>
        <button type="submit">Change Password</button>
      </form>
      <p style={{ color: response.toLowerCase().includes('successful') ? 'green' : 'red' }}>{response}</p>
      {/* Footer */}
      <Footer/>
    </div>
  );
}

export default ChangePasswordScreen;
