import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './axiosConfig'; // Import Axios interceptors configuration
import { useNavigate } from 'react-router-dom';
import * as CryptoJS from 'crypto-js';


function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [response, setResponse] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log('Submitting login form...'); // Debug message

    try {
      // Hash the password using SHA256
      const hashedPassword = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
      console.log('Hashed Password:', hashedPassword);

      const response = await axios.post('https://thechanguyenvankhoi.server.daolin.top/login',
      {
        username: username.toLowerCase(),
        password: hashedPassword, // Send hashed password to the server
      });

      console.log('Login API request successful'); // Debug message

      // Handle login based on response status
      if (response.status === 200)
      {
        // Login successful
        setResponse('Login successful');

        // Save token to cookie if present
        if (response.headers.authorization)
        {
          Cookies.set('token', response.headers.authorization,
          { 
            expires: 7, 
            secure: true // Set secure option to true
          });


          console.log('token', response.headers.authorization); // Debug message

          if(response.data.message === 'Please change your password')
          {
            // Navigate to CheckInOutScreen after successful login
            navigate('/change-password');
          }
          else
          {
            // Navigate to CheckInOutScreen after successful login
            navigate('/HomePage');
          }
        }
        else
        {
          console.log('Token not found'); // Debug message
        }
      }
      else
      {
        // Other error
        setResponse(`Error: ${response.status}`);
      }
    }
    catch (error)
    {
      console.error('Error:', error);
      if (error.response.status === 401)
      {
        // Unauthorized access
        setResponse('Unauthorized: Access Denied');
      }
      else
      {
        setResponse(`Error: ${error.message}`);
      }

    }
  };

  return (
    <div>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Username:</label>
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </div>
        <div>
          <label>Password:</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <button type="submit">Login</button>
      </form>
      <p style={{ color: response.toLowerCase().includes('successful') ? 'green' : 'red' }}>{response}</p>
    </div>
  );
}

export default Login;
